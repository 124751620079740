@use "../../../../master.scss";

.dashboard-header {
    @include master.flex-between();
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    background: master.$white;
    height: 70px;
    padding: 0 20px;
    border-bottom: 1px solid master.$border-color;

    .left-box {
        @include master.flex-start();
        gap: 35px;

        .logo-box {
            color: master.$black;
            @include master.flex-start();
            gap: 10px;

            img {
                width: 55px;
            }

            h1 {
                font-size: 22px;

                span {
                    font-family: master.$font-arial-bold;
                    margin-right: 4px;
                }

                @include master.media-mobile {
                    font-size: 18px;
                }
            }
        }
    }

    .toggle-btn {
        display: none;
        background: transparent;
        border: none;

        span {
            display: block;
            width: 25px;
            height: 4px;
            background: master.$theme-color;
            margin-bottom: 5px;
            border-radius: 20px;
            transition: .3s all;

            &:nth-child(2) {
                width: 21px;
            }

            &:last-child {
                margin-bottom: 0;
                width: 15px
            }

            @include master.media-mobile {
                height: 3px;
            }
        }

        &.active {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(6.5px, 1px);
                }

                &:nth-child(2) {
                    width: 25px;
                    transform: rotate(-45deg) translate(4.5px, 1px);
                }

                &:nth-child(3) {
                    display: none
                }

            }
        }

        @include master.media-tablet {
            @include master.flex-end-column();
        }
    }

    .right-box {
        @include master.flex-end();
        gap: 25px;

        .create-dropdown {
            .selected-box {
                background: master.$light-black;
                color: master.$white;
                border-color: master.$light-black;
                padding: 8px 18px;
                padding-right: 40px;

                span {
                    width: 80px
                }
            }

            .dropdown-options {
                background: master.$light-black;
                border-color: master.$light-black;
                top: 33px;

                button {
                    color: master.$white;
                    border-color: master.$black;

                    &:hover {
                        background: master.$black;
                    }
                }

                .dropdown-option {
                    display: flex;
                    gap: .5rem;
                }
            }

            @include master.media-tablet {
                display: none
            }
        }

        .user-profile {
            .profile-box {
                @include master.flex-center();
                cursor: pointer;
                background: transparent;
                border: none;
                text-align: left;

                .thumb {
                    overflow: hidden;
                    border-radius: 50px;
                    width: 40px;
                    height: 40px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover
                    }
                }

                .caret {
                    margin-left: 5px;
                    transition: .3s all;

                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }

            .profile-options {
                position: absolute;
                right: 0;
                top: 48px;
                border-radius: 8px;
                background: master.$white;
                box-shadow: 0 0 4px master.$grey;
                width: 150px;
                z-index: 9;
                height: 0;
                transition: .3s all;
                overflow: hidden;

                li {
                    display: block;

                    button {
                        width: 100%;
                        border: none;
                        background: transparent;
                        text-align: left;
                        padding: 10px 10px;
                        font-size: 14px;
                        border-bottom: 1px solid master.$offwhite;
                        cursor: pointer;
                        transition: .3s all;

                        &:hover {
                            background: master.$offwhite;
                        }
                    }

                    &:last-child button {
                        border: none
                    }
                }

                &.visible {
                    height: max-content
                }
            }

            @include master.media-tablet {
                display: none;
            }
        }
    }

}