@use "../../../master.scss";

.projects {
  .filteration-box {
    @include master.custom-flex(flex-end, flex-start);
    gap: 20px;
    label {
      display: block;
      font-size: 15px;
      color: master.$light-black;
      margin-bottom: 10px;
      svg {
        margin-left: 8px;
        color: master.$dark-grey;
      }
    }
    .ant-select {
      width: 220px;
    }
    .search-box {
      width: 450px;
      max-width: 100%;
      .input-box {
        @include master.flex-start();
        .filter-btn {
          position: absolute;
          right: 15px;
          background: transparent;
          color: master.$theme-color;
          border: none;
          font-size: 20px;
          display: none;
          @include master.media-desktop {
            display: block;
          }
        }
      }
    }
    .filter-drawer {
      @include master.flex-start();
      gap: 20px;
      @include master.media-desktop {
        position: fixed;
        background: master.$white;
        bottom: 0;
        right: 0;
        height: 100%;
        z-index: 99;
        flex-direction: column;
        border-left: 1px solid master.$border-color;
        padding: 0 15px;
        width: 250px;
        padding-top: 100px;
        transform: translateX(250px);
        pointer-events: none;
        transition: 0.3s all;
        &.active {
          transform: translateX(0);
          pointer-events: all;
        }
      }
    }

    .sort-dropdown {
      padding-bottom: 2px;
      .selected-box {
        border: none;
        padding: 0;
        padding-right: 20px;

        .placeholder {
          font-size: 16px;
          width: auto;

          span {
            font-family: master.$font-arial-bold;
            min-width: 85px;
            display: inline-block;

            @include master.media-smalldevice {
              min-width: 60px;
            }
          }

          @include master.media-smalldevice {
            font-size: 14px;
          }
        }

        .toggle {
          right: 0;
        }
      }

      .dropdown-options {
        border-top-color: master.$border-color;
        border-radius: 6px;

        button {
          font-size: 15px;

          @include master.media-smalldevice {
            font-size: 13px;
          }
        }
      }
      @include master.media-smalldevice {
        margin: 0;
        margin-top: 10px;
        width: 150px;
      }
    }
    @include master.media-smalldevice {
      display: block;
    }
  }
  .data-wrapper {
    // height: calc(100vh - 270px);
    height: calc(100vh - 200px);
    // padding-top: 40px;
    .no-data {
      height: 100%;
      @include master.flex-center();
      color: master.$theme-color;
      font-size: 20px;
      font-family: master.$font-arial-bold;
    }
    .data-listing {
      max-height: 100%;
      overflow-y: auto;
      width: 85%;
      width: 100%;

      .list-item {
        text-align: left;
        cursor: pointer;
        margin-bottom: 25px;
        padding: 5px 16px;
        border: 1px solid master.$border-color;
        transition: 0.3s all;

        @include master.flex-start();
        .tagline {
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          height: 100%;
          background: master.$success-color;

          &.error {
            background: master.$red;
          }
        }
        .thumb {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
        .detail {
          width: calc(100% - 160px);
          @include master.flex-start();
          h3 {
            font-family: master.$font-arial-bold;
            font-size: 16px;
            width: 30%;
            @include master.text-wrap();

            @include master.media-smalldevice {
              width: 100%;
              white-space: initial;
              margin-top: 15px;
            }
          }
          p {
            width: 70%;
            padding-left: 10px;
            @include master.text-wrap();

            @include master.media-smalldevice {
              width: 100%;
              padding-left: 0;
              white-space: initial;
              font-size: 14px;
              margin-top: 10px;
            }
          }
          @include master.media-smalldevice {
            width: 100%;
            @include master.flex-start-column();
          }
        }
        .action-box {
          width: 100px;
          @include master.flex-end();
          gap: 15px;
          color: master.$theme-color;
          * {
            cursor: pointer;
          }
          @include master.media-smalldevice {
            position: absolute;
            right: 15px;
            top: 20px;
          }
        }
        .tag {
          position: absolute;
          right: 10px;
          background: master.$success-color;
          color: #fff;
          padding: 6px 20px;
          border-radius: 5px;
          text-transform: capitalize;
        }
        @include master.media-smalldevice {
          @include master.flex-start-column();
          padding: 10px 15px;
          border-radius: 5px;
          overflow: hidden;
        }
        &:hover {
          border: 1px solid master.$border-color-hover;
        }
      }
    }
  }
  @include master.media-smalldevice {
    .page-title {
      padding-bottom: 10px;
    }
  }
}
