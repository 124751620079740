@use "../../../../../master.scss";

.chat-box {
  .splash-screen {
    height: 100%;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 50px;

    .basic-detail {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      height: 160px;

      .ant-image {
        width: 85px;
        height: 85px;
        img {
          object-fit: cover;
        }
        @include master.media-desktop {
          width: 65px;
          height: 65px;
        }
        @include master.media-mobile {
          width: 45px;
          height: 45px;
        }
      }
      .detail {
        width: calc(100% - 100px);
        padding-top: 10px;
        h3 {
          font-size: 36px;
          font-family: master.$font-arial-bold;
          @include master.text-wrap();
          @include master.media-desktop {
            font-size: 26px;
          }
          @include master.media-mobile {
            font-size: 22px;
          }
        }
        p {
          font-size: 20px;
          @include master.line-wrap(2);
          @include master.media-desktop {
            font-size: 18px;
          }
          @include master.media-mobile {
            font-size: 15px;
          }
        }
        @include master.media-mobile {
          padding-top: 3px;
        }
      }
      @include master.media-desktop {
        height: 110px;
      }
      @include master.media-mobile {
        gap: 10px;
        height: 50px;
      }
    }
    .chat-starters {
      max-height: calc(100% - 160px);
      overflow: hidden;
      overflow-y: auto;
      .starter-box {
        h3 {
          font-family: master.$font-arial-bold;
          margin-bottom: 20px;
          @include master.media-mobile {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 16px;
          }
        }
        .starters {
          button {
            width: 100%;
            background: transparent;
            display: block;
            text-align: left;
            padding: 20px 16px;
            border: 1px solid master.$input-border;
            border-radius: 4px;
            margin-top: 16px;
            transition: 0.3s all;
            cursor: pointer;
            &:nth-child(1) {
              margin-top: 0;
            }
            &:hover {
              border-color: master.$theme-color;
            }
            @include master.media-mobile {
              padding: 12px;
              margin-top: 10px;
            }
          }
        }
      }
      @include master.media-desktop {
        max-height: calc(100% - 110px);
      }
      @include master.media-mobile {
        max-height: calc(100% - 50px);
      }
    }
    @include master.media-desktop {
      padding-top: 30px;
    }
    @include master.media-mobile {
      padding-top: 20px;
    }
  }
}
