@use "../../../../master.scss";

.access-modal {
  margin: 30px 0 !important;
  max-width: calc(100% - 60px);
  .ant-modal-content {
    padding: 50px 55px 60px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
    }
    .top-header {
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid master.$grey;

      .title {
        font-size: 28px;
        font-family: master.$font-arial-bold;
        width: calc(100% - 180px);
        &:not(.sub-title)::before {
          content: "";
          position: absolute;
          width: 8px;
          height: calc(100% - 14px);
          top: 7px;
          left: 0;
          background: master.$theme-color-primary;
        }
        .back-btn {
          font-size: 20px;
          margin-right: 20px;
          cursor: pointer;
        }
        &:not(.sub-title) {
          padding-left: 20px;
        }
      }
      .description {
        font-size: 15px;
        padding-left: 20px;
      }
      button {
        position: absolute;
        right: 10px;
        top: 15px;
        background: transparent;
        border: none;
        color: master.$theme-color;
        border-bottom: 1px solid master.$theme-color;
        cursor: pointer;
        svg {
          width: 20px;
        }
        span {
          margin-left: 6px;
          border-bottom: 1px solid master.$theme-color;
          cursor: pointer;
        }
      }
      &.access-list {
        padding-bottom: 0;
        border: none;
      }
    }
    .invite-box {
      display: flex;
      margin-bottom: 30px;
      gap: 15px;
      .ant-form-item {
        margin: 0;
        width: 100%;
        .ant-input {
          padding: 9px 15px;
        }
      }
      button {
        background: master.$grey;
        color: master.$black;
        border: none;
        border-radius: 50px;
        height: 42px;
        padding: 7px 37px;
        width: 150px;
        font-family: master.$font-arial-bold;
        font-size: 15px;
        min-width: auto;
        &:hover {
          background: master.$light-grey;
        }
        &:disabled {
          background: master.$light-grey !important;
          opacity: 0.8;
        }
      }
    }
    .upload-list {
      display: inline-block;
      background: transparent;
      border: none;
      border-bottom: 1px solid master.$theme-color;
      margin: 15px 0 30px;
      font-size: 14px;
      color: master.$theme-color;
    }
    h4 {
      font-family: master.$font-arial-bold;
      font-size: 15px;
      margin-bottom: 15px;
    }

    .ant-select {
      width: 100%;
      height: 40px !important;
      svg {
        margin-right: 10px;
      }
    }
    .users-detail-list {
      margin-bottom: 25px;
      &.scrollable {
        overflow-y: auto;
        max-height: 210px;
      }
    }
    .user-detail {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      border: 1px solid master.$border-color;
      border-radius: 6px;
      padding: 10px 15px;
      margin-bottom: 25px;
      .select-box {
        width: 21px;
        height: 21px;
        //margin-right: 26px;
      }
      .thumb {
        text-transform: uppercase;
        width: 36px;
        height: 36px;
        background: master.$theme-color;
      }
      .detail {
        background: transparent;
        border: none;
        text-align: left;
        h4,
        p {
          margin: 0;
          line-height: 20px;
        }
      }
      .owner-tag {
        position: absolute;
        right: 15px;
        background: master.$light-grey;
        padding: 8px 20px;
        font-family: master.$font-arial-bold;
        width: 131px;
        text-align: center;
        border-radius: 50px;
      }
      .user-permission {
        position: absolute;
        right: 15px;
        gap: 15px;
        @include master.flex-end();
        .selected-box {
          background: master.$light-grey;
          border: none;
          padding-top: 8px;
          padding-bottom: 8px;
          .placeholder {
            width: 65px;
          }
        }
        .dropdown-options {
          background: master.$light-grey;
          border: none;
          top: 28px;
          button:hover {
            background: master.$grey;
          }
        }
        .remove-btn {
          font-size: 20px;
          background: transparent;
          cursor: pointer;
          color: master.$disabled-text;
          border: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
        &.overflow {
          .dropdown-options {
            top: auto !important;
            bottom: 30px;
            border-radius: 16px 16px 0 0;
          }
          .custom-dropdown.active .selected-box {
            border-radius: 0 0 16px 16px;
          }
        }
      }
    }

    /* Container for both carets and titles */    
      .sorting-container {      
      display: flex;
      justify-content: space-between; /* Distribute space between left and right */
      padding: 0px 0px 0px; /* Adjust padding */
    }

    .caret-name-container {
      display: flex;
      //align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-right: 365px;
      font-size: 1rem;
      font-weight: bold;
    }
    
    /* Individual containers for caret and title */
    .caret-role-container {
      display: flex;
      //align-items: center;
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 80px;
      font-size: 1rem;
      font-weight: bold;
    }

    /* Style for the caret icon */
    .caret-name-container span, .caret-role-container span {
      margin: 0 8px; /* Space between caret and text */
    }
    
    .welcome-toggle {
      margin-bottom: 10px;
      span {
        font-size: 15px;
      }
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        &::after {
          inset-inline-start: 30%;
        }
      }
    }
    .shared-list {
      .ant-checkbox-inner {
        width: 21px;
        height: 21px;
      }
      .top-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: '50px'; 
        overflow: 'hidden';
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid master.$grey;
        * {
          font-size: 15px;
        }

        .search-bar-container {
          width: 350px;
        }

        .manage-btn {
          border-radius: 50px;
        }
    }
      .users-listing {
        .user-detail {
          border: none;
          padding: 0;
          margin-bottom: 20px;
        }
        &.scrollable {
          overflow-y: auto;
          max-height: 500px;
        }
      }
    }
    .action-box {
      margin-top: 50px;
      margin-bottom: 10px;
      @include master.flex-between();

      button {
        font-size: 15px;
        min-width: 140px;
        border-radius: 30px;
        padding: 15px 25px;
        font-family: master.$font-arial-bold;

        svg {
          margin-right: 5px;
          min-width: 20px;
        }

        &.back-btn {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
  }
}

.remove-access {
  margin: 30px 0 !important;
  max-width: calc(100% - 60px);
  .ant-modal-content {
    padding: 50px 55px 60px;

    .ant-modal-close {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      &-icon {
        font-size: 18px;
      }
    }

    .title {
      font-size: 32px;
      padding-left: 20px;
      font-family: master.$font-arial-bold;
      width: calc(100% - 130px);
      margin-bottom: 15px;
      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: calc(100% - 14px);
        top: 7px;
        left: 0;
        background: master.$theme-color-primary;
      }
    }

    p {
      font-size: 17px;
      margin-bottom: 20px;
      b {
        font-family: master.$font-arial-bold;
      }
    }

    .action-box {
      margin-top: 50px;
      @include master.flex-between();

      button {
        font-size: 16px;
        min-width: 120px;
        border-radius: 30px;
        padding: 12px 30px;
        font-family: master.$font-arial-bold;

        &.back-btn {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
  }
}
