@use "../../../../../../master.scss";

.project-knowledge-base {
  .project-files {
    border-bottom: 1px solid master.$border-color;
    padding-bottom: 60px;
    margin-bottom: 50px;
    .uploader {
      margin-top: 10px;
      @include master.flex-start();
      gap: 10px;
      .upload-btn {
        border-radius: 50px;
        padding: 8px 25px;
        font-size: 15px;

        input {
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
    .filter-box {
      @include master.flex-start();
      margin-top: 40px;
      gap: 15px;
      .search-input {
        background: master.$light-grey;
        color: master.$dark-text;
        border: 1px solid transparent;
        border-radius: 50px;
        padding: 9px 20px;
        min-width: 280px;
        &::placeholder {
          color: master.$dark-text;
        }
        &:focus {
          border-color: master.$border-color;
        }
      }
      .tags-filter {
        .selected-box {
          border: none;
          background: master.$light-grey;
          padding: 9px 20px;
          padding-right: 30px;
          border-radius: 50px;
        }
        .dropdown-options {
          top: 40px;
          width: 150px;
          background: master.$offwhite;
          border-radius: 10px;
          button {
            padding: 10px 12px;
            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
            }
            .label {
              top: 3px;
            }
            &:hover {
              background: master.$light-grey;
            }
          }
        }
        &.active .dropdown-options {
          border: 1px solid master.$light-grey !important;
        }
      }
      .filter-btn {
        border-radius: 50px;
        padding: 8px 25px;
        font-size: 15px;
        background: master.$light-grey;
        color: master.$black;
        &:hover {
          background: master.$grey;
        }
        &.selected {
          background: master.$black;
          color: master.$white;
        }
      }
    }
    .files-listing {
      margin-top: 30px;
      *,
      *::before,
      *::after {
        border-color: master.$border-color !important;
      }
      .ant-table-cell {
        h5 {
          font-family: master.$font-arial-bold;
          color: master.$theme-color;
          text-align: left;
          font-size: 16px;
          svg {
            cursor: pointer;
            margin-left: 5px;
          }
        }
        h6 {
          font-size: 13px;
          svg {
            cursor: pointer;
            margin-left: 3px;
          }
        }
        .status-label {
          font-size: 14px;
          margin-left: 8px;
          bottom: 1px;
        }
        &.select-cell {
          min-width: 60px;
          text-align: center;
          padding: 0;
          button {
            min-width: 100%;
            text-align: center;
            padding: 16px;
            background: transparent;
            border: none;
            cursor: pointer;

            .ant-checkbox-inner {
              width: 22px;
              height: 22px;
              &::after {
                width: 7px;
                height: 12px;
                top: 45%;
                border-color: master.$white !important;
              }
            }
          }
        }
        &.title-cell,
        &.reference-cell,
        &.expose-cell {
          padding: 0;
          h5,
          h6 {
            padding: 16px;
          }
          button {
            min-width: 100%;
            text-align: left;
            padding: 16px;
            background: transparent;
            border: none;
            cursor: pointer;

            .file-icon {
              color: master.$theme-color;
              font-size: 16px;
              margin-right: 5px;
            }
          }
          &.title-cell button {
            display: flex;
            align-items: flex-start;
            > span:last-child,
            .file-icon {
              top: 2px;
            }
          }
        }
        &.reference-cell,
        &.expose-cell {
          button {
            color: master.$dark-grey;
            font-size: 18px;
            &.active {
              color: master.$theme-color;
            }
          }
        }
        &.tags-cell {
          min-width: 150px;
          max-width: 250px;
        }
        &.delete-cell {
          min-width: 70px;
          text-align: center;
          button {
            background: transparent;
            border: none;
            color: master.$venetian-red;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
    .action-box {
      @include master.flex-end();
      gap: 15px;
      margin-top: 30px;
      button {
        border-radius: 50px;
        min-width: 145px;
        min-height: 35px;
        &.delete-btn {
          background: master.$grey !important;
          color: master.$black !important;
          &:hover {
            background: master.$border-color !important;
          }
          &:disabled {
            opacity: 0.7;
            pointer-events: none;
          }
        }
      }
    }
  }
}
