@use "../../../master.scss";

.dashboard {
  padding: 50px 0;
  text-align: center;

  .intro-box {
    padding-bottom: 35px;
    text-align: left;

    h1 {
      margin-bottom: 20px;
      border-left: 8px solid master.$theme-color-primary;
      font-size: 40px;
      font-family: master.$font-arial-bold;
      padding-left: 10px;
      line-height: 30px;

      @include master.media-smalldevice {
        font-size: 22px;
        border-width: 6px;
        padding-left: 6px;
        margin-bottom: 10px;
      }
    }

    .search-bar-container {
      max-width: 100%;
      @include master.flex-between();

      .ant-input-affix-wrapper {
        border-radius: 8px;
        padding: 8px 10px;
        width: 300px;
        max-width: 100%;
        font-size: 16px;
        .thumb {
          color: master.$theme-color;
        }
        .ant-input {
          padding-left: 5px;
        }
      }
    }

    h4 {
      font-size: 16px;
      @include master.media-smalldevice {
        font-size: 14px;
      }
    }
    @include master.media-smalldevice {
      padding-bottom: 25px;
    }

    .section-description {
      font-size: 16px;
      line-height: 24px;
    }

    &.borderless {
      h1,
      div {
        border: none;
        padding-left: 0;
      }
    }
  }

  // CREATE PROJECT SECTION
  .create-project-wrapper {
    @include master.flex-start();
    flex-wrap: wrap;
    gap: 30px;

    .create-project-box {
      width: 300px;
      max-width: 100%;
      padding: 15px;
      height: 250px;
      border: 1px dashed master.$border-color;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s all;
      @include master.flex-center-column();

      .thumb {
        width: 55px;
        height: 55px;
        border: 4px solid master.$border-color;
        color: master.$border-color;
        border-radius: 50px;
        font-size: 26px;
        margin-bottom: 15px;
        transition: 0.3s all;
        @include master.flex-center();
      }

      h1 {
        font-family: master.$font-arial-bold;
        font-size: 18px;
        margin-bottom: 4px;
      }

      p {
        color: master.$dark-grey;
        min-height: 40px;
        font-size: 16px;
      }

      &:hover {
        border-color: master.$theme-color;

        .thumb {
          color: master.$theme-color;
          border-color: master.$theme-color;
        }
      }

      @include master.media-smalldevice {
        width: 100%;
      }
    }

    @include master.media-smalldevice {
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  // PROJECTS AND RESOURCES SECTION
  .projects-wrapper,
  .resources-wrapper {
    .title-box {
      @include master.flex-between();

      h1 {
        font-family: master.$font-arial-bold;
        font-size: 24px;

        @include master.media-smalldevice {
          font-size: 20px;
        }
      }

      .sort-dropdown {
        .selected-box {
          border: none;
          padding: 0;
          padding-right: 20px;

          .placeholder {
            font-size: 16px;
            width: auto;

            span {
              min-width: 85px;
              display: inline-block;

              @include master.media-smalldevice {
                min-width: 60px;
              }
            }

            @include master.media-smalldevice {
              font-size: 14px;
            }
          }

          .toggle {
            right: 0;
          }
        }

        .dropdown-options {
          border-top-color: master.$border-color;
          border-radius: 6px;

          button {
            font-size: 15px;

            @include master.media-smalldevice {
              font-size: 13px;
            }
          }
        }

        @include master.media-mobile {
          margin: 0;
        }
      }

      @include master.media-smalldevice {
        @include master.flex-start-column();
        gap: 10px;
      }
    }

    .project-section {
      margin-top: 50px;

      &.recent-projects {
        .section-description {
          max-width: 602px;
        }
      }

      &.create-projects {
        .section-description {
          max-width: 726px;
        }
      }

      &.shared-projects {
        .section-description {
          max-width: 715px;
        }
      }
    }

    .no-data {
      height: 220px;
      @include master.flex-center();
      color: master.$theme-color;
      font-size: 16px;
      font-family: master.$font-arial-bold;
    }

    .cards-listing {
      margin-top: 20px;

      margin-bottom: 50px;
      .card {
        padding: 20px 16px;
        text-align: left;
        overflow: hidden;
        min-height: 163px;

        &.card-tall {
          height: 260px;
        }

        .tagline {
          position: absolute;
          right: 0;
          top: 0;
          background: master.$success-color;
          color: #fff;
          padding: 6px 20px;
          border-bottom-left-radius: 5px;
          text-transform: capitalize;

          &.error {
            background: master.$red;
          }
        }

        .thumb-container {
          display: flex;
          gap: 1rem;
          align-items: center;

          .project-status {
            display: inline-block;
            padding: 4px 18px;
            border-radius: 4px;
            text-align: center;
            font-family: master.$font-arial-bold;

            &.draft {
              background-color: master.$light-grey;
              color: master.$black;
            }

            &.live {
              color: master.$white;
              background-color: master.$success-color;
            }

            &.error {
              color: master.$white;
              background-color: master.$red;
            }
          }
        }

        .like-btn {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
          height: 40px;
          width: 40px;
          z-index: 100;
          border: none;
          background: inherit;
          @include master.flex-center();

          .like {
            cursor: pointer;
            height: 16px;
            width: 16px;

            &.favorite {
              color: master.$theme-color-primary;
            }
          }
        }

        .thumb {
          border-radius: 50px;
          display: flex;
          justify-content: center;

          &.thumb-big {
            width: 100px;
            height: 100px;
          }

          &.thumb-small {
            width: 60px;
            height: 60px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50px;
          }
        }

        .title {
          font-size: 16px;
          font-family: master.$font-arial-bold;
          margin: 16px 0 8px;
          @include master.text-wrap();
        }

        .description {
          color: master.$light-black;
          font-size: 16px;
          min-height: 40px;
          line-height: 24px;
          @include master.line-wrap(2);
        }

        .modified {
          position: absolute;
          bottom: 15px;
          color: master.$dark-grey;
        }

        &.view-all {
          border-style: dashed;
          @include master.flex-center();

          h1 {
            color: master.$theme-color;
            font-size: 16px;
            text-decoration: underline;
          }
        }
      }

      .create-card {
        min-height: 100px;
        cursor: pointer;
        border: 1px solid master.$border-color;
        transition: box-shadow 0.3s ease;

        &:hover {
          border-color: master.$theme-color-primary;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        }

        .title {
          margin: 0;
          line-height: 24px;
        }

        .data-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;

          .icon-container {
            flex: 0 0 10%;
          }

          .text-container {
            flex: 0 0 90%;
            display: flex;
            flex-direction: column;
          }

          .description-small {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .card-wrapper {
        border: 1px solid master.$border-color;
        .card-footer {
          display: flex;
          align-items: flex-start;
          background-color: master.$light-grey;
          padding: 15px;
        }

        .side {
          display: flex;
          gap: 0.25rem;
          justify-content: center;
          flex-direction: column;
          max-width: 50%;
        }
      }

      .toggle-show-all {
        cursor: pointer;
        margin-top: 25px;
        text-decoration: underline;
        color: master.$venetian-red;

        p {
          font-size: 16px;
        }
      }
    }
  }

  .banner-wrapper {
    @include master.flex-start();
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    .image-box {
      flex: 0 0 60%;
      @include master.flex-center();
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }

    .detail {
      flex: 0 0 40%;
      @include master.flex-start-column();
      text-align: left;
      border: 1px solid master.$border-color;
      padding: 15px 30px;

      h2 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
        font-family: "ArialBold";
      }

      p {
        font-size: 16px;
        color: #555;
        margin-bottom: 20px;
      }

      .cta {
        font-weight: bold;
      }
    }

    @include master.media-smalldevice {
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .seperator {
    background-color: master.$theme-color-primary;
    height: 15px;
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @include master.media-smalldevice {
    padding: 30px 0;
  }
}
