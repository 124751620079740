@use "../../../../master.scss";

.dashboard-sidebar {
    top: 70px;
    transition: .4s all;
    position: fixed;
    height: calc(100% - 70px);
    width: 260px;
    left: 0;
    padding: 10px 0;
    background: master.$white;
    border-right: 1px solid master.$border-color;
    transform: translateX(-260px);
    z-index: 99;

    .sidebar-toggle {
        background: master.$white;
        border: 1px solid master.$border-color;
        border-radius: 50px;
        position: absolute;
        right: -50px;
        width: 34px;
        height: 34px;
        font-size: 13px;
        top: 12px;
        @include master.flex-center();
        gap: 3px;
        cursor: pointer;
        z-index: 999;
        &:hover {
          background: master.$light-grey;
        }
        @include master.media-tablet {
          display: none;
        }
    }

    .mobile-box {
        padding: 0 20px;
        display: none;

        .user-profile {
            .thumb {
                // outline: 2px solid master.$theme-color;
                // border: 2px solid master.$white;
                width: 80px;
                height: 80px;
                display: block;
                border-radius: 50%;
                overflow: hidden;

                .ant-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .username {
                display: block;
                margin-top: 15px;
                font-size: 18px;
            }
        }

        .create-dropdown {
            margin: 15px 0;

            .selected-box {
                padding: 9px 12px;
                border-radius: 6px;
                @include master.flex-start();

                span {
                    font-size: 15px;
                    width: 120px;
                }

                .toggle {
                    font-size: 13px;
                }
            }

            .dropdown-options {
                border-radius: 0 0 6px 6px;
                top: 34px;

                button {
                    padding: 10px 12px;
                }
            }
        }

        @include master.media-tablet {
            display: block;
        }
    }

    .nav-menu {
        li {
            display: block;

            a {
                padding: 14px 20px;
                color: master.$light-black;
                font-size: 16px;
                display: block;
                transition: .3s all;
                @include master.flex-start();

                .thumb {
                    font-size: 18px;
                    margin-right: 10px;
                }

                &:hover,
                &.active {
                    background: master.$light-grey;
                }

                &.active .title {
                    font-family: master.$font-arial-bold;
                }

                @include master.media-tablet {
                    padding: 12px 20px;
                    font-size: 13px;
                }
            }
        }

        @include master.media-tablet {
            margin-top: 25px;
            padding-top: 10px;
            border-top: 1px solid master.$border-color;
        }
    }

    @include master.media-mobile {
        padding: 0 14px;
    }

    @include master.media-desktop {
        width: 240px;
        transform: translateX(-240px);
    }

    @include master.media-tablet {
        transform: translateX(0);
    }

    @include master.media-mobile {
        padding: 20px 0;
    }

    &.active {
        transform: translateX(0);

        .sidebar-toggle {
            right: -17px;
        }

        @include master.media-tablet {
            transform: translateX(-240px);
        }
    }
}