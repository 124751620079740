@use "../../../../../../master.scss";

.setting-sidebar {
  .advance-tab {
    .basic-settings {
      margin-bottom: 15px;
      .title {
        @include master.flex-between();
        font-family: master.$font-arial-bold;
        font-size: 15px;
        margin-bottom: 10px;
        button {
          color: master.$theme-color;
          background: transparent;
          text-decoration: underline;
          border: none;
          cursor: pointer;
        }
      }
      .detail {
        margin-top: 5px;
        p {
          font-size: 14px;
          @include master.line-wrap(3);
        }
        h4 {
          font-size: 14px;
          font-family: master.$font-arial-bold;
          &.theme-text {
            color: master.$theme-color;
          }
          &.underline {
            text-decoration: underline;
          }
        }
        &.inline {
          @include master.flex-start();
          gap: 5px;
          flex-wrap: wrap;
          a {
            max-width: calc(100% - 60px);
            h4 {
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }

    .other-actions {
      border-top: 1px solid master.$light-grey;
      margin-bottom: 20px;
      h4 {
        font-family: master.$font-arial-bold;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 12px;
        font-size: 13px;
      }
      .action-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
      }
      button {
        background: master.$white;
        color: master.$black;
        border: 1px solid master.$border-color;
        font-family: master.$font-arial-bold;
        border-radius: 50px;
        padding: 8px 18px;
        min-width: auto;
        font-size: 12px;

        &:hover {
          background: master.$light-grey;
        }
        &.delete-btn {
          background: master.$pink-red;
          border-color: master.$red;
        }
      }
    }

    .used-model-dropdown {
      border: 1px solid master.$border-color;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 20px;

      .selected-option {
        cursor: pointer;
        background: transparent;
        border: none;
        text-align: left;
        padding: 15px 30px 15px 45px;
        .ant-radio-wrapper {
          position: absolute;
          left: 15px;
          top: calc(50% - 8px);
          .ant-radio-checked .ant-radio-inner {
            background: master.$border-color !important;
            &::after {
              background: master.$theme-color;
              width: 20px;
              height: 20px;
              margin-inline-start: -10px;
              margin-block-start: -10px;
            }
          }
        }
        h4 {
          font-family: master.$font-arial-bold;
          margin-bottom: 6px;
        }
        p {
          font-size: 13px;
          color: master.$dark-text;
          margin-bottom: 0;
          @include master.line-wrap(3);
        }
        .toggle-btn {
          position: absolute;
          right: 10px;
          top: 15px;
          transition: 0.3s all;
        }
      }
      .dropdown-options {
        max-height: 150px;
        overflow: hidden;
        height: 0;
        transition: height 0.3s;
        button {
          cursor: pointer;
          padding: 10px 15px 10px 45px;
          font-size: 12px;
          color: master.$dark-text;
          border: none;
          background: transparent;
          text-align: left;
          border-bottom: 1px solid master.$light-grey;
          .ant-radio-wrapper {
            position: absolute;
            left: 15px;
            top: calc(50% - 8px);
            .ant-radio-inner {
              border-color: master.$theme-color;
              background: master.$border-color;
            }
          }
          span {
            display: block;
            &:first-child {
              font-size: 13px;
              font-family: master.$font-arial-bold;
              margin-bottom: 6px;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      &.active {
        .selected-option .toggle-btn {
          transform: rotate(180deg);
        }
        .dropdown-options {
          overflow-y: auto;
          height: max-content;
        }
      }
    }

    .temperature-slider {
      margin-top: 20px;
      margin-bottom: 20px;

      .ant-tooltip-content {
        top: 6px;

        .ant-tooltip-inner {
          font-size: 13px;
        }
      }
    }

    .media-accordion {
      p {
        font-size: 13px;
        margin-bottom: 15px;

        &.note {
          font-family: master.$font-arial-bold;
          color: master.$theme-color;
        }
      }
    }

    .ui-accordion {
      .image-uploader {
        margin-bottom: 24px;
      }
    }
  }
}

.delete-project-modal {
  .ant-modal-content {
    padding: 50px 55px 40px;

    .ant-modal-close {
      right: 30px;
      top: 30px;
    }
    .title {
      font-size: 28px;
      font-family: master.$font-arial-bold;
      margin-bottom: 5px;
      padding-left: 20px;
      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: calc(100% - 14px);
        top: 7px;
        left: 0;
        background: master.$theme-color-primary;
      }
    }
    .description {
      font-size: 15px;
      margin-bottom: 30px;
      padding-bottom: 25px;
      border-bottom: 1px solid master.$grey;
    }
    label {
      display: block;
      font-family: master.$font-arial-bold;
      margin-bottom: 5px;
    }
    .ant-input {
      width: 400px;
      max-width: 100%;
    }

    .action-box {
      margin-top: 50px;
      margin-bottom: 10px;
      @include master.flex-between();

      button {
        font-size: 16px;
        min-width: 140px;
        border-radius: 30px;
        padding: 10px 30px;
        font-family: master.$font-arial-bold;

        &.back-btn {
          background: master.$light-grey;
          color: master.$black;

          &:hover {
            background: master.$grey;
          }
        }

        @include master.media-smalldevice {
          min-width: 100px;
          padding: 9px 20px;
          font-size: 14px;
        }
      }
    }
  }
}
