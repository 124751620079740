@use "../../master.scss";

.custom-dropdown {

    .selected-box {
        cursor: pointer;
        border: 1px solid master.$border-color;
        border-radius: 16px;
        padding: 6px 16px;
        padding-right: 50px;
        width: 100%;
        background: transparent;
        text-align: left;
        @include master.flex-start();

        .toggle {
            position: absolute !important;
            right: 12px;
            font-size: 14px;
            transition: .3s all;

            @include master.media-desktop {
                right: 12px;
            }

        }

        .placeholder {
            width: 60px;
            display: block;
            font-size: 14px;
            font-family: master.$font-arial-bold;
            @include master.text-wrap();

            @include master.media-mobile {
                font-size: 12px;
            }
        }
    }

    .dropdown-options {
        position: absolute;
        width: 100%;
        top: 30px;
        z-index: 2;
        background: master.$white;
        transition: height .3s;
        border-radius: 0 0 16px 16px;
        height: 0;
        overflow: hidden;
        max-height: 240px;
        overflow-y: auto;

        button {
            width: 100%;
            background: transparent;
            border: none;
            text-align: left;
            padding: 8px 16px;
            cursor: pointer;
            transition: .3s all;
            display: flex;
            border-bottom: 1px solid master.$offwhite;
            gap: 10px;

            &:last-child {
                border: none;
            }

            &:hover {
                background: master.$offwhite;
            }

            &.selected {
                background: master.$light-grey;
            }

            &.no-data {
                color: master.$theme-color;
                font-family: master.$font-arial-bold;
                margin: 10px 0;

                &:hover {
                    background: transparent;
                }

                @include master.media-mobile {
                    font-size: 14px;
                }
            }

            @include master.media-desktop {
                padding: 10px 14px;
            }

            @include master.media-mobile {
                padding: 8px 14px;
            }
        }

        @include master.media-mobile {
            top: 28px;
        }
    }

    &.active {
        .selected-box {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: transparent;

            .toggle {
                transform: rotate(180deg);
            }
        }

        .dropdown-options {
            height: max-content;
            border: 1px solid master.$border-color;
            border-top-color: transparent;
        }

    }

    @include master.media-mobile {
        margin: 20px 14px 0;
    }
}