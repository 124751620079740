@use "../../../../master.scss";

.project-header {
  @include master.flex-between();
  background: master.$white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 70px;
  padding: 10px 40px;
  border-bottom: 1px solid master.$border-color;

  .detail-box {
    width: calc(100% - 270px);
    @include master.flex-start();
    gap: 20px;

    .back-btn {
      @include master.flex-start();
      color: master.$black;
      font-size: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        @include master.media-tablet {
          display: none;
        }
      }
      @include master.media-tablet {
        background: master.$offwhite;
        width: 30px;
        font-size: 14px;
        border-radius: 50px;
        height: 30px;
        @include master.flex-center();
      }
      @include master.media-smalldevice {
        position: absolute;
        top: 0;
      }
    }

    h4 {
      max-width: calc(100% - 300px);
      font-family: master.$font-arial-bold;
      font-size: 22px;
      @include master.text-wrap();

      @include master.media-smalldevice {
        max-width: 100%;
        font-size: 18px;
      }
    }

    .new-chat {
      margin-left: 5px;
      background: transparent;
      border: none;
      font-size: 16px;
      color: master.$light-black;
      cursor: pointer;
      @include master.flex-start();
      .thumb {
        font-size: 18px;
        width: 21px;
        height: 21px;
        background: master.$light-black;
        color: master.$white;
        border-radius: 50px;
        @include master.flex-center();
        @include master.media-smalldevice {
          width: 30px;
          height: 30px;
          background: master.$light-grey;
          color: master.$black;
          font-size: 15px;
        }
      }
      .title {
        margin-left: 8px;
        @include master.media-smalldevice {
          display: none;
        }
      }
    }
    @include master.media-smalldevice {
      padding-top: 42px;
      @include master.flex-between();
      width: 100%;
      gap: 17px;
    }
  }

  .action-box {
    @include master.flex-end();
    gap: 15px;

    .view-app {
      background: master.$success-color;
      color: master.$black;
      padding: 8px 20px;
      border-radius: 16px;
      span {
        font-size: 14px;
        font-family: master.$font-arial-bold;
        @include master.media-smalldevice {
          display: none;
        }
      }
      svg {
        margin-left: 8px;
        @include master.media-smalldevice {
          margin: 0;
        }
      }
      @include master.media-smalldevice {
        width: 30px;
        height: 30px;
        font-size: 12px;
        border-radius: 50px;
        @include master.flex-center();
      }
    }

    .access-btn {
      background: transparent;
      border: 1px solid master.$dark-grey;
      @include master.flex-center();
      gap: 6px;
      padding: 8px 24px;
      border-radius: 50px;
      cursor: pointer;
      span {
        font-family: master.$font-arial-bold;
        font-size: 15px;
      }
      svg {
        position: relative;
        bottom: 1px;
      }
      &:hover {
        background: master.$light-grey;
      }
    }

    .save-btn {
      border-radius: 16px;
      font-family: master.$font-arial-bold;

      svg {
        margin-left: 5px;
      }
    }
    .setting-btn {
      cursor: pointer;
      display: none;
      background: master.$offwhite;
      border: none;
      width: 30px;
      border-radius: 50px;
      height: 30px;
      font-size: 14px;

      @include master.media-tablet {
        @include master.flex-center();
      }
    }
    @include master.media-smalldevice {
      position: absolute;
      top: 10px;
      right: 15px;
      gap: 10px;
    }
  }

  @include master.media-tablet {
    padding: 10px 20px;
  }
  @include master.media-smalldevice {
    padding: 10px 15px;
    height: 94px;
    align-items: flex-start;
  }
}
